<template>

  <div class="home">
    <img class="body-background" src="/ressources/mobile-body-back.png">
    <div class="overlay"></div>
      <div class="home-content">
        <img src="/ressources/net-logo.png" class="net-app-logo">

        <div class="login-formsec" >
          <div class="log-sumtsec" v-if="!updatepsw">
              <otp-input @otp-complete="handleCode"></otp-input>
          </div>
          <div class="log-sumtsec" v-if="updatepsw">
            <input  type="password" v-model="password" class="login-input usernm-input" placeholder="Password"/>
            <input class="login-input " v-model="confpassword" placeholder="Confirm Password"/>
           </div>
           <div class="fogsubmit-sec" v-if="updatepsw">
              <button type="submit" @click="resetPassword()" class="login-btn">Change Password</button>
            </div>
          </div>
          <div class="login-footersec">
               <p v-show="crmmessage"  :type="alertType" v-text="crmmessage" class="msg-txt"></p>
               <p v-show="message"  :type="alertType" v-text="message" class="msg-txt"></p>
              <div v-if="!updatepsw"  @click="resendverificationPin()" class="forgt-link">Resend Pin?</div>
          </div>
        </div>
      </div>



</template>

<script>

import OtpInput from '@/components/Otp.vue';
import api from "@/api";

export default {
  name: 'App',
  components: {
  OtpInput
  },
  data(){
    return {
      otpCode: '',
      updatepsw:false,
      showresendpin:false,
      message:'',
      password:'',
      confpassword:'',
      crmmessage:'',
    }
  },
  methods: {
 async handleCode(otpCode){
  this.otpCode = otpCode;

  let form= {
                email: this.$route.query.email,
                token:otpCode
            };
  console.log(form)
  this.isLoading = true;
            try {

                await api.auth.getCookie();
                let result= await api.auth.verifyPin(form);
                if(result.data.success){
                  this.message='';
                  this.updatepsw=true;
                }else{
                    this.message =result.data.message;
                }

            } catch (err) {
                const errors = err.response.data.errors;
                this.isLoading=false;
                if (errors) {
                    if(errors["email"]){
                        this.error = errors["email"] ? errors["email"][0] : "";
                    }else{
                        this.error = errors["password"] ? errors["password"][0] : "";
                    }
                } else if (err.response.status === 419) {
                    this.error =
                        "The token is expired. Please refresh the page.";
                }

                else {
                    this.error = err.response.data.message;
                }
            } finally {
                this.isLoading = false;
            }

  },


async resendverificationPin() {
    try {
          this.isLoading = true;
          var t=  await api.auth.sendResetPassword({email:this.$route.query.email});
          this.isSent = t.data.success;
          this.message = t.data.message; //"The email has been successfully sent.";
          this.updatepsw=false;
            } catch (err) {
                this.message = err.response.data.errors
                    ? err.response.data.errors["email"][0]
                    : err.response.data.message;
                this.alertType = "error";
            } finally {
                this.isLoading = false;
            }
},
async resetPassword(){
  console.log('g');
  if(this.confpassword !=this.password){
    this.crmmessage='Password & Confirm Password Should be same';
    return;
  }else if(this.confpassword=='' && this.password==''){
    this.crmmessage='Please Put Password & Confirm Password  Field Value';
  }
  else if(this.confpassword==''){
    this.crmmessage='Pleas put  Confirm Password  Field Value';
  }
  else if(this.password==''){
    this.crmmessage='Pleas put   Password  Field Value';
  }
  else if(this.password <8 || this.confpassword < 8 ){
    this.crmmessage='Pleas put  Minimum 8 digit password/confirm password';
  }
  else{
    let form= {
                password: this.password,
                password_confirmation:this.confpassword,
                email:this.$route.query.email
            };
  this.isLoading = true;
            try {

                await api.auth.getCookie();
                let result= await api.auth.resetPassword(form);
                if(result.data.success){
                  this.message='';
                  this.updatepsw=true;
                  location.href = "/login";
                }else{
                    this.message =result.data.message;
                }

            } catch (err) {
                const errors = err.response.data.errors;
                this.isLoading=false;
                if (errors) {
                    if(errors["email"]){
                        this.error = errors["email"] ? errors["email"][0] : "";
                    }else{
                        this.error = errors["password"] ? errors["password"][0] : "";
                    }
                } else if (err.response.status === 419) {
                    this.error =
                        "The token is expired. Please refresh the page.";
                }

                else {
                    this.error = err.response.data.message;
                }
            } finally {
                this.isLoading = false;
            }





  }
}
},
}
</script>

<style>
   *,
        ::before,
        ::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        }

        body{
            overflow:hidden;
            font-family: "Open Sans", sans-serif;
        }

        .home {
        height: 100vh;
        position: relative;
        overflow: hidden;
        }
        .body-background{
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        }
        .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        background: rgba(0,0,0,0.3);
        }
        .home-content {
        max-width: 600px;
        position: relative;
        top: 60%;
        color: #fff;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -60%);
        text-align: center;
        }

        .home-content .net-app-logo {
        width: 200px;
        height: auto;
        }
        .app-logosec {
        width: 300px;
        margin: 0 auto;
        display: flex;
        }
        .login-formsec
        {
        max-width: 80%;
        margin: 0 auto;
        padding: 30px 60px 30px 60px;
        background: #ffffff82;
        }
        .login-formsec input{
        width: 100%;
        color: #fff;
        font-size: 18px;
        outline: none;
        box-shadow: none;
        }
        .log-sumtsec{
        position: relative;
        }
        .submit-sec
        {
        float: right;

        }

        .submit-sec button
        {
        background: #484848;
        border: none;
        padding: 15px;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        font-size: 18px;
        color: #fff;
        }
        .loginsec
        {
        border: 15px solid #1c1e21;
        background: transparent;
        }
        .login-formsec input
        {
        width: 100%;
        padding: 10px;
        background: #1c1e21;
        border: none;
        outline:0
        }
        .login-formsec .usernm-input{
        margin-bottom: 10px;
        }
        .submit-sec
        {
        float: right;
        position: absolute;
        right: -30px;
        bottom: 30px;
        }
        .forgt-link
        {
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        margin: 25px 0 15px 0;
        }
        .cret-accnt
        {
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;

        }
        .msg-txt
        {
        font-size: 16px;
        margin: 20px 0 0 20px;
        }
        .loginsec .login-input::placeholder{
        color:#f5f5f5;
        font-size: 14px;
        }
        .forgt-link span{
            font-weight:700;
        }
        @media screen and (min-device-width: 300px) and (max-device-width: 500px) {
            .login-formsec
        {
        max-width: 100%;
        margin-top: 30px;

        }
        .submit-sec
        {

        right: -35px;
        bottom: 25px;
        }
        }
.w-12 {
    width: 3rem;
}

.h-12 {
    height: 3rem;
}
.rounded-xl {
    border-radius: 0.75rem;
}
.border-slate-300 {
    --tw-border-opacity: 1;
    border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-2 {
    border-width: 2px;
}
.caret-teal-400 {
    caret-color: #2dd4bf;
}


.text-slate-700 {
    --tw-text-opacity: 1;
    color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
}

.w-8 {
    width: 2rem;
}

.h-8 {
    height: 2rem;
}
</style>