<template>
        <div class="flex items-center space-x-4">
        <input
          v-for="(input, index) in otpLength"
          :key="index"
          ref="otpInput"
          v-model="otpArray[index]"
          @keydown="handleEnter(index, $event)"
          @input="handleInput(index, $event)"
          @paste="handlePaste(index, $event)"
          type="text"
          step="1"
          maxlength="1"
          class="h-12 w-12 rounded-xl text-3xl text-center text-slate-700 caret-teal-400 border-2 border-slate-300 placeholder:text-slate-400 focus:outline-none focus:border-teal-400"
        />
    </div>

  </template>
  <script>
  export default {
    data() {
      return {
        joinedValue: "",
        otpArray: [],
        error: "",
      };
    },
    props: {
      otpLength: {
        type: Number,
        default: 6,
      },
    },
    methods: {
      handleEnter(index, event) {
        const keypressed = event.keyCode || event.which;
        if (
          (keypressed < 48 || keypressed > 57) &&
          keypressed !== 8 &&
          !event.ctrlKey
        ) {
          event.preventDefault();
        }
        if (keypressed === 8 || keypressed === 46) {
          event.preventDefault();
          if (!this.otpArray[index] && index > 0) {
            this.otpArray[index - 1] = "";
            this.$refs.otpInput[index - 1].value = "";
            this.$refs.otpInput[Math.max(0, index - 1)].focus();
          } else {
            this.otpArray[index] = "";
            this.$refs.otpInput[index].value = "";
          }
        }
      },
      handleInput(index, event) {
        if (index < this.otpLength - 1) {
          this.$refs.otpInput[index + 1].focus();
        }
        if (index === this.otpLength - 1) {
          this.$refs.otpInput[index].blur();
        }

        if (event.inputType === "insertFromPaste") {
          this.handlePasteEvent(index)
        }
        this.joinedValue = this.otpArray.join("");
            if (this.joinedValue.length === this.otpLength) {
              this.$emit("otp-complete", this.joinedValue);
            }
      },
      handlePasteEvent(index){
        navigator.clipboard.readText().then((pastedText) => {
            const shavedText = pastedText.replace(/[^0-9]/g, '')
            for (let i = 0; i < shavedText.length && index + i < this.otpLength; i++) {
              this.otpArray[index + i] = shavedText.charAt(i);
              if (index + i + 1 < this.otpLength) {
                this.$refs.otpInput[index + i + 1].dispatchEvent(
                  new Event("input")
                );
              }
            }
          });
      },
      handlePaste(index, event) {
        this.handlePasteEvent(index)
        this.$emit("paste", event);
      },
    },
  };
  </script>
  <style>

  </style>